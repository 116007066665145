
























































import { UpdateCODNoteInput } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BCollapse,
  BCard,
  BCardBody,
  BCardText,
  BCardHeader
} from 'bootstrap-vue';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { orderListDispatcher } from '../store/order-list.module';
import { MODAL_IDS, PAYMENT_NOTE_TYPE } from '@/utility/constants';
import ActivityLogModal from '@/components/activityLog/ActivityLogModal.vue';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BCollapse,
    ActivityLogModal,
    BCard,
    BCardBody,
    BCardText,
    BCardHeader
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: ''
      },
      dispatcher: orderListDispatcher
    })
  ]
})
export default class ActivityLogSummaryModal extends Vue {
  @Prop({ type: Object, required: true })
  noteData!: {
    orderId: number;
    type: string;
    amount: number | null | undefined;
    note: string | null | undefined;
  };

  MODAL_IDS = MODAL_IDS;
  PAYMENT_NOTE_TYPE = PAYMENT_NOTE_TYPE;
  orderListDispatcher = orderListDispatcher;

  $refs!: {
    modal: InstanceType<typeof BModal>;
  };

  openModal(entityName, entityId, showId): void {
    this.$refs.modal.show();
  }
}
