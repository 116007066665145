import {
  ProductCategoryViewModel,
  ProductViewModel,
  ProcessingTypeViewModel,
  PolymeCoverTypesViewModel,
  PriceViewModel,
  PricingType,
  MasterDataViewModel,
  OrderDetailViewModel,
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  ProcessingSettingModel
} from './../../../api/api';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BSpinner,
  BOverlay
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { CreateOrderDetailInput, CreateOrderInput } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { convertCurrencyToNumber, formatDate } from '@/utility/utils';
import ApiClientFactory from '@/api/apiClientFactory';
import { formatCurrency } from '@/utility/utils';
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import OrderDetailTable from './OrderDetailTable.vue';
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';
import {
  ProductAction,
  ProductState,
  PRODUCT_STATE_NAMESPACE
} from '@/store/product/product.module-types';
import {
  ProductCategoryAction,
  ProductCategoryState,
  PRODUCT_CATEGORY_STATE_NAMESPACE
} from '@/store/productCategory/productCategory.module-types';
import {
  OrderState,
  ORDER_STATE_NAMESPACE
} from '@/store/order/order.module-types';
import arSA from 'date-fns/esm/locale/ar-SA/index';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { ValidationProvider } from 'vee-validate';
import OrderDetailCatalogueForm from './OrderDetailCatalogueForm.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    CustomLabel,
    BFormSelectOption,
    BFormRadio,
    BFormCheckbox,
    BSpinner,
    ModelSelect,
    OrderDetailTable,
    vSelect,
    BOverlay,
    OrderDetailCatalogueForm
  },
  computed: {
    ...mapState(PRODUCT_STATE_NAMESPACE, [ProductState.products]),
    ...mapState(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryState.productCategories
    ]),
    ...mapState(ORDER_STATE_NAMESPACE, [OrderState.orderMaterial]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryAction.fetchProductCategories
    ]),
    ...mapActions(PRODUCT_STATE_NAMESPACE, [ProductAction.fetchProducts]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class OrderForm extends Vue {
  @Prop({ type: CreateOrderInput, required: true })
  orderInput!: CreateOrderInput;
  @Prop({ type: Boolean, required: true })
  isLoading!: boolean;
  @Prop({ type: Number, required: true })
  orderId!: number;

  $refs!: {
    orderForm: InstanceType<typeof ValidationProvider>;
    addServiceFormRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    tableRef: InstanceType<typeof OrderDetailTable>;
    pricePerUnitRef: InstanceType<typeof BFormInput>;
    catalogueOrderDetailRef: any;
  };
  //orderInput: CreateOrderInput = this.getDefaultInput();

  pricingType: PricingType | undefined = undefined;
  standeeNote = '';

  //Vuex
  products!: ProductViewModel[];
  fetchProducts!: () => Promise<void>;
  productCategories!: ProductCategoryViewModel[];
  fetchProductCategories!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  formatDate = formatDate;

  processingTypes: ProcessingTypeViewModel[] = [];
  processingTypes2: ProcessingTypeViewModel[] = [];
  polymeCoverTypes: PolymeCoverTypesViewModel[] = [];
  productFiltered: ProductViewModel[] = [];
  productPrices: PriceViewModel[] = [];
  priceReferences: OrderDetailViewModel[] = [];
  catalogueProductItems: CatalogueOrderMaterial[] = [];
  processingSettings: ProcessingSettingModel[] = [];

  defaultServiceInputValue: CreateOrderDetailInput = new CreateOrderDetailInput(
    {
      //type: OrderDetailType.Product,
      serviceId: 0,
      machining: '',
      cutType: '',
      quantity: 0,
      width: 0,
      height: 0,
      pricePerUnit: 0,
      note: '',
      area: 0,
      categoryId: 0,
      depositAmount: 0,
      includeVAT: false,
      polymeCoverTypeId: '',
      processingTypeId2: '',
      processingTypeId: '',
      totalPrice: 0,
      vatAmount: 0,
      couponCode: '',
      fileName: '',
      sample: undefined
    } as CreateOrderDetailInput
  );
  orderDetailInput: CreateOrderDetailInput = {
    ...this.defaultServiceInputValue
  } as CreateOrderDetailInput;
  selectedServiceToEditIndex?: number = undefined;

  selectedCategoryId = 0;
  searchProductSelected: number | null = null;
  usingReferncePrice = false;
  standeeNoteOptions: DropdownOption[] = [
    {
      value: 'Có hàng in Standee kèm theo',
      text: 'Có hàng in Standee kèm theo'
    },
    { value: 'Không có hàng in Standee', text: 'Không có hàng in Standee' },
    {
      value: 'Thay standee khách mang tới',
      text: 'Thay standee khách mang tới'
    },
    { value: 'Đóng gói đi tỉnh', text: 'Đóng gói đi tỉnh' },
    { value: 'Giao gần', text: 'Giao gần' }
  ];

  catalogueOrderDetail: {
    catalogueItemId: string;
    catalogueItemName: string;
    size: string;
    width: number;
    height: number;
    processingType: string;
    polymeCoverType: string;
    polymeCoverTypeName: string;
    totalPage: number;
    totalBook: number;
    pricePerUnit: number;
    totalPrice: number;
  } = {
    catalogueItemId: '',
    catalogueItemName: '',
    size: '',
    width: 0,
    height: 0,
    processingType: '',
    polymeCoverType: '',
    polymeCoverTypeName: '',
    totalPage: 0,
    totalBook: 0,
    pricePerUnit: 0,
    totalPrice: 0
  };

  selectedProcessingSetting: ProcessingSettingModel[] = [];
  selectedProcessingItemIds: any = [];

  mounted() {
    //this.isLoading = true;
    Promise.all([
      this.fetchProductCategories(),
      this.fetchProducts(),
      this.fetchMasterData()
    ])
      .catch((err) => {
        this.$bvToast.toast('Tải thông tin thất bại', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        //this.isLoading = false;
      });

    //this.orderId = parseInt(this.$route.params.id);
  }

  formatCurrency = formatCurrency;

  get isCatalogueProducts(): boolean {
    //this.orderDetailInput.categoryId === this.masterData?.catalogueProductId;
    return this.orderDetailInput.categoryId === 13;
  }

  serviceFormSubmit(): void {
    this.$refs.addServiceFormRules.validate().then((success) => {
      if (!success) {
        return;
      }

      if (this.orderDetailInput.quantity > 10000000) {
        this.$bvToast.toast('Số lượng vượt quá giới hạn 10 000 000', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
        return;
      }

      const updateItem = this.prepareOrderDetail();

      if (this.orderDetailInput.serviceId && success) {
        if (this.selectedServiceToEditIndex !== undefined) {
          this.orderInput.createOrderDetails.splice(
            this.selectedServiceToEditIndex,
            1
          );
          this.orderInput.createOrderDetails.splice(
            this.selectedServiceToEditIndex,
            0,
            updateItem
          );
        } else {
          this.orderInput.createOrderDetails.push(this.prepareOrderDetail());
          this.selectedServiceToEditIndex = undefined;
        }

        this.orderDetailInput = {
          ...this.defaultServiceInputValue,
          serviceId: this.orderDetailInput.serviceId,
          categoryId: this.orderDetailInput.categoryId,
          processingTypeId: this.orderDetailInput.processingTypeId,
          processingTypeId2: this.orderDetailInput.processingTypeId2,
          polymeCoverTypeId: this.orderDetailInput.polymeCoverTypeId,
          pricePerUnit: this.orderDetailInput.pricePerUnit
        } as CreateOrderDetailInput;
        this.selectedServiceToEditIndex = undefined;
        this.selectedCategoryId = 0;
        this.standeeNote = '';
      }
      this.catalogueOrderDetail = {
        ...this.catalogueOrderDetail,
        size: '',
        width: 0,
        height: 0,
        processingType: '',
        polymeCoverType: '',
        polymeCoverTypeName: '',
        totalPage: 0,
        totalBook: 0,
        totalPrice: 0
      };
    });
  }

  prepareOrderDetail(): CreateOrderDetailInput {
    this.setOrderDetailNote();
    if (
      this.orderDetailInput.categoryId === this.masterData.catalogueCategoryId
    ) {
      this.orderDetailInput.width = this.catalogueOrderDetail.width;
      this.orderDetailInput.height = this.catalogueOrderDetail.height;
      this.orderDetailInput.catalogueProductItemId = this.catalogueOrderDetail.catalogueItemId;
      this.orderDetailInput.totalPage = this.catalogueOrderDetail.totalPage;
      this.orderDetailInput.polymeCoverTypeId = this.catalogueOrderDetail.polymeCoverType;
      this.orderDetailInput.pricePerUnit = this.catalogueOrderDetail.pricePerUnit;
      this.orderDetailInput.quantity = this.catalogueOrderDetail.totalBook;
      this.orderDetailInput.totalPrice = this.catalogueOrderDetail.totalPrice;

      this.polymeCoverTypes = [
        ...this.polymeCoverTypes,
        {
          id: this.catalogueOrderDetail.polymeCoverType,
          name: this.catalogueOrderDetail.polymeCoverTypeName
        } as PolymeCoverTypesViewModel
      ];
      this.catalogueProductItems.push({
        catalogueProductItemId: this.catalogueOrderDetail.catalogueItemId,
        catalogueProductItemName: this.catalogueOrderDetail.catalogueItemName,
        sizeOptions: [
          {
            name: this.catalogueOrderDetail.size,
            height: this.catalogueOrderDetail.height,
            width: this.catalogueOrderDetail.width
          } as CatalogueSizeOption
        ]
      } as CatalogueOrderMaterial);
    }

    const processingSettingModelSelectedKeys = Object.keys(
      this.selectedProcessingItemIds
    );
    if (
      this.selectedProcessingItemIds &&
      processingSettingModelSelectedKeys.length > 0
    ) {
      const processingSettingModelSelecteds = processingSettingModelSelectedKeys.map(
        (processingId) => {
          const processing = this.processingSettings.find(
            (s) => s.id == processingId
          );

          const processingItem = processing?.processingSettingModelItems?.find(
            (s) => s.id == this.selectedProcessingItemIds[processingId]
          );

          return {
            id: processing?.id,
            name: processing?.name,
            processingSettingModelItems: [processingItem]
          } as ProcessingSettingModel;
        }
      );

      //this.selectedProcessingItemIds = [];
      this.orderDetailInput.processingSettings = processingSettingModelSelecteds;
    }

    return {
      ...this.orderDetailInput,
      quantity: convertCurrencyToNumber(this.orderDetailInput.quantity),
      vatAmount:
        convertCurrencyToNumber(this.orderDetailInput.totalPrice) * 0.08,
      pricePerUnit: convertCurrencyToNumber(this.orderDetailInput.pricePerUnit),
      totalPrice: convertCurrencyToNumber(this.orderDetailInput.totalPrice),

      processingTypeName: this.processingTypes.find(
        (s) => s.id === this.orderDetailInput.processingTypeId
      )?.name,
      processingTypeName2: this.processingTypes2?.find(
        (s) => s.id === this.orderDetailInput.processingTypeId2
      )?.name,
      polymeCoverTypeName: this.polymeCoverTypes?.find(
        (s) => s.id === this.orderDetailInput.polymeCoverTypeId
      )?.name
    } as CreateOrderDetailInput;
  }

  setOrderDetailNote() {
    if (this.isStandeeSelecting()) {
      const regx = /\[NOTE:(.*?)\]/g;
      if (!this.standeeNote) {
        this.orderDetailInput.note = this.orderDetailInput.note?.replace(
          regx,
          ''
        );
        return;
      }

      const standeeNoteMatching = this.orderDetailInput.note?.match(regx);
      if (standeeNoteMatching) {
        this.orderDetailInput.note = this.orderDetailInput.note?.replace(
          regx,
          `[NOTE: ${this.standeeNote}]`
        );
      } else {
        this.orderDetailInput.note =
          `[NOTE: ${this.standeeNote}] ` + this.orderDetailInput.note;
      }
    }
  }

  isStandeeSelecting() {
    return (
      this.masterData?.standeeCategoryIds?.indexOf(
        this.orderDetailInput.categoryId
      ) !== -1
    );
  }

  async loadOrderMaterials(serviceId: number, onEditing: boolean) {
    if (
      serviceId !== 0 &&
      this.orderDetailInput.categoryId === this.masterData.catalogueCategoryId
    ) {
      this.onProductCategoryChange();
      this.orderDetailInput.serviceId = serviceId;
      if (!onEditing) {
        this.catalogueOrderDetail.catalogueItemId = '';
        this.catalogueOrderDetail.polymeCoverType = '';
      }

      this.$nextTick(function () {
        this.$refs.catalogueOrderDetailRef
          .fetchCatalogueMaterial(serviceId)
          .then((s) => {
            if (onEditing) {
              this.$refs.catalogueOrderDetailRef.setPolymeCoverTypes(false);
            }
          });
      });
      return;
    }

    if (serviceId) {
      //this.isLoading = true;
      const clientApi = new ApiClientFactory();
      const result = await clientApi
        .orderClient()
        .getOrderMaterialByProductId(serviceId);

      this.orderDetailInput.categoryId = result.categoryId;
      this.productFiltered = this.products.filter(
        (val) => val.productCategoryId === this.orderDetailInput.categoryId
      );
      this.orderDetailInput.serviceId = result.productId;

      this.processingTypes = result.processingType ?? [];
      this.polymeCoverTypes = result.polymeCoverType ?? [];
      this.processingTypes2 = result.processingType2 ?? [];
      this.productPrices = result.prices ?? [];
      this.processingSettings = result.processingSettings ?? [];

      this.pricingType = result.pricingType;

      if (
        this.orderDetailInput.processingSettings &&
        this.orderDetailInput.processingSettings.length > 0
      ) {
        this.orderDetailInput.processingSettings.forEach((s) => {
          if (s.processingSettingModelItems) {
            this.selectedProcessingItemIds[s.id] =
              s.processingSettingModelItems[0].id;
          }
        });
      }

      this.priceReferences = await clientApi
        .orderClient()
        .getReferencePrices(this.orderInput.customerId, serviceId);

      this.checkReferencePrice(onEditing);
      //this.isLoading = false;
    }
  }

  checkReferencePrice(onEditing: boolean) {
    let priceReference = this.priceReferences.find(
      (s) =>
        (s.polymeCoverTypeId ?? '') ===
          this.orderDetailInput.polymeCoverTypeId &&
        (s.processingTypeId ?? '') === this.orderDetailInput.processingTypeId
    );
    if (
      this.orderDetailInput.processingTypeId2 &&
      this.orderDetailInput.processingTypeId2 !== ''
    ) {
      priceReference = this.priceReferences.find(
        (s) =>
          s.polymeCoverTypeId === this.orderDetailInput.polymeCoverTypeId &&
          s.processingTypeId === this.orderDetailInput.processingTypeId &&
          s.processingTypeId2 === this.orderDetailInput.processingTypeId2
      );
    }

    const price =
      onEditing === true
        ? this.orderDetailInput.pricePerUnit
        : priceReference?.pricePerUnit ?? 0;
    this.orderDetailInput.pricePerUnit = price;

    this.usingReferncePrice = price !== 0;
    return price;
  }

  async onSearchChange(data) {
    this.selectedProcessingItemIds = [];
    this.orderDetailInput.categoryId =
      this.products.find((s) => s.id === data)?.productCategoryId ?? 0;

    await this.loadOrderMaterials(data, false);
    this.searchProductSelected = null;
    this.orderDetailInput.polymeCoverTypeId = '';
    this.orderDetailInput.processingTypeId = '';

    // this.orderDetailInput.pricePerUnit = 0;
    this.orderDetailInput.totalPrice = 0;
  }

  onProductCategoryChange() {
    this.productFiltered = this.products
      .filter(
        (val) => val.productCategoryId === this.orderDetailInput.categoryId
      )
      .sort((a, b) => (a?.displayNumber ?? 0) - (b?.displayNumber ?? 0));
    this.orderDetailInput.serviceId = 0;
    this.searchProductSelected = null;
    this.orderDetailInput.polymeCoverTypeId = '';
    this.orderDetailInput.processingTypeId = '';
    this.processingTypes = [];
    this.processingTypes2 = [];
    this.polymeCoverTypes = [];
    this.selectedProcessingItemIds = [];
  }

  onWidthHeightChange() {
    const area =
      this.orderDetailInput.width *
      this.orderDetailInput.height *
      this.orderDetailInput.quantity;
    this.orderDetailInput.area = parseFloat(area.toFixed(10));
  }

  onPriceChange(ignoreDefaultPrice) {
    this.onWidthHeightChange();

    let price = this.orderDetailInput.pricePerUnit;
    if (ignoreDefaultPrice !== true) {
      price = this.checkReferencePrice(false);
    }

    if (
      //this.orderDetailInput.totalPrice === 0 &&
      !this.usingReferncePrice &&
      ignoreDefaultPrice !== true
    ) {
      price = this.getPricePerUnit();
    }

    this.orderDetailInput.pricePerUnit = price;
    //this.$refs.pricePerUnitRef.focus();
    const isPricingByQuantity =
      (this.pricingType && this.pricingType === PricingType.Quantity) ||
      (this.orderDetailInput.quantity !== 0 &&
        this.orderDetailInput.width === 0 &&
        this.orderDetailInput.height === 0);
    if (isPricingByQuantity) {
      this.orderDetailInput.totalPrice =
        convertCurrencyToNumber(this.orderDetailInput.quantity) *
        convertCurrencyToNumber(this.orderDetailInput.pricePerUnit);
      return;
    }

    if (this.pricingType && this.pricingType === PricingType.Sticker) {
      this.orderDetailInput.totalPrice =
        convertCurrencyToNumber(this.orderDetailInput.area) *
        convertCurrencyToNumber(this.orderDetailInput.pricePerUnit);
      return;
    }

    if (this.pricingType && this.pricingType === PricingType.Size) {
      this.orderDetailInput.totalPrice =
        this.orderDetailInput.area *
        convertCurrencyToNumber(this.orderDetailInput.pricePerUnit);
      return;
    }

    // const area = this.orderDetailInput.width * this.orderDetailInput.height;
    // this.orderDetailInput.area = parseFloat(area.toFixed(10));
    this.orderDetailInput.totalPrice =
      (this.orderDetailInput.area === 0 ? 1 : this.orderDetailInput.area) *
      convertCurrencyToNumber(this.orderDetailInput.pricePerUnit);
  }

  getPricePerUnit() {
    let finalPrice = 0;
    if (
      this.pricingType &&
      this.pricingType === PricingType.Quantity &&
      this.productPrices.length > 0
    ) {
      const priceSorted = this.productPrices[0].value!.sort(
        (a, b) => b.minQuantity - a.minQuantity
      );
      const price = priceSorted!.find(
        (s) => this.orderDetailInput.quantity >= s.minQuantity
      );
      finalPrice =
        (!price ? priceSorted[priceSorted?.length - 1].price : price.price) ??
        0;
      return finalPrice;
    }

    if (
      this.pricingType &&
      this.pricingType === PricingType.Size &&
      this.productPrices.length > 0
    ) {
      const ereaInputted =
        this.orderDetailInput.width * this.orderDetailInput.height;

      const priceSorted = this.productPrices[0]
        .value!.filter((s) => s.area === ereaInputted)
        .sort((a, b) => b.minQuantity - a.minQuantity);

      const price = priceSorted!.find(
        (s) => this.orderDetailInput.quantity >= s.minQuantity
      );
      finalPrice =
        (!price ? priceSorted[priceSorted?.length - 1].price : price.price) ??
        0;
      return finalPrice;
    }

    const isStickerPrice =
      this.pricingType && this.pricingType === PricingType.Sticker;
    if (isStickerPrice) {
      const haveMachining =
        this.orderDetailInput.polymeCoverTypeId !==
        this.polymeCoverTypes.find((s) => s.isNotProcessedValue)?.id;
      const cutType =
        this.orderDetailInput.processingTypeId !==
        this.processingTypes.find((s) => s.isNotProcessedValue)?.id
          ? 2
          : 1;

      const pricesByMaterial = this.productPrices.filter(
        (s) =>
          //s.customerLevel === this.orderInput.customerLevel &&
          s.customerLevel === 1 &&
          s.machining === haveMachining &&
          s.cutType == cutType
      );

      if (pricesByMaterial && pricesByMaterial.length > 0) {
        const square =
          this.orderDetailInput.width *
          this.orderDetailInput.height *
          this.orderDetailInput.quantity;
        const priceSorted =
          pricesByMaterial[0].value?.sort((a, b) => b.area - a.area) ?? [];

        const price = priceSorted.find((s) => square >= s.area) ?? 0;
        const maxPrice = priceSorted
          ? priceSorted[priceSorted.length - 1].price
          : 0;
        finalPrice =
          (((price ? price.price : maxPrice) ?? 0) * square) /
          this.orderDetailInput.quantity;
      }
    }

    return finalPrice;
  }

  async onEditOrderDetail(data) {
    this.selectedServiceToEditIndex = data.index;
    this.orderDetailInput = {
      ...data.item
    } as CreateOrderDetailInput;
    await this.loadOrderMaterials(this.orderDetailInput.serviceId, true);

    if (
      this.orderDetailInput.categoryId === this.masterData.catalogueCategoryId
    ) {
      this.catalogueOrderDetail = {
        catalogueItemId: this.orderDetailInput.catalogueProductItemId ?? '',
        height: this.orderDetailInput.height,
        width: this.orderDetailInput.width,
        polymeCoverType: data.item.polymeCoverTypeId ?? '',
        pricePerUnit: this.orderDetailInput.pricePerUnit,
        totalBook: this.orderDetailInput.quantity,
        totalPage: this.orderDetailInput.totalPage,
        totalPrice: this.orderDetailInput.totalPrice,

        size: '',
        catalogueItemName: '',
        polymeCoverTypeName: '',
        processingType: ''
      };
    }
  }

  onSelectProcessingSettingModel(event, processingSettingId) {
    delete this.selectedProcessingItemIds[processingSettingId];
    if (event) {
      this.$set(this.selectedProcessingItemIds, processingSettingId, event);
    }
  }

  onSelectControlClose() {
    this.$refs.pricePerUnitRef.focus();
  }

  onReset() {
    this.selectedServiceToEditIndex = undefined;
    this.orderDetailInput = {
      ...this.defaultServiceInputValue
    } as CreateOrderDetailInput;

    this.catalogueOrderDetail = {
      catalogueItemId: '',
      catalogueItemName: '',
      size: '',
      width: 0,
      height: 0,
      processingType: '',
      polymeCoverType: '',
      polymeCoverTypeName: '',
      totalPage: 0,
      totalBook: 0,
      pricePerUnit: 0,
      totalPrice: 0
    };
  }
}
