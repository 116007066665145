


















































































































































import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { orderListDispatcher } from '../store/order-list.module';
import { GUID_EMPTY, MODAL_IDS } from '@/utility/constants';
import vSelect from 'vue-select';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatCurrency,
  formatDate,
  StringFormat
} from '@/utility/utils';
import {
  OrderDepositModel,
  OrderPaymentMethod,
  UpdateOrderPaymentMethodInput
} from '@/api/api';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { VueGoodTable } from 'vue-good-table';
import { listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { DEFAULT_ORDER_PAGE_SIZE } from '@/utility/pagination/pagination';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueGoodTable
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: ''
      },
      dispatcher: orderListDispatcher
    })
  ]
})
export default class DepositInputModal extends Vue {
  depositAmount = 0;
  paymentMethodInput = 0;
  orderDeposits: OrderDepositModel[] = [];
  orderDepositInput: OrderDepositModel = this.getInitValue();
  depositAmountResult = 0;
  isPaidStatus = false;
  isUpdating = false;

  MODAL__IDS = MODAL_IDS;
  formatCurrency = formatCurrency;
  formatDate = formatDate;
  orderListDispatcher = orderListDispatcher;

  rejectPromise: any;
  resolvePromise: any;

  headers = [
    {
      field: 'paymentMethod',
      label: 'Hình thức',
      sortable: false,
      width: '30%'
    },
    {
      field: 'amount',
      label: 'Số tiền',
      sortable: false
    },
    {
      field: 'date',
      label: 'Ngày',
      sortable: false
    },
    {
      field: 'note',
      label: 'Ghi chú',
      sortable: false
    },
    {
      field: '_action',
      label: '',
      sortable: false
    }
  ];

  orderPaymentMethodOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentMethod
  ).map((s) => {
    return {
      value: s.value,
      text: s.text
    };
  });

  getInitValue() {
    return {
      amount: 0,
      paymentMethod: 0,
      date: new Date(),
      note: ''
    } as OrderDepositModel;
  }

  get titleModal() {
    return this.isPaidStatus ? 'Thanh toán' : 'Tạm ứng';
  }

  rowStyleClassFn(row) {
    const regex = /^[a-z,0-9,-]{36,36}$/;
    if (this.isPaidStatus) {
      if (regex.test(row.id)) {
        return 'disabled';
      }
    }

    return '';
  }

  show(orderId, isPaidStatus) {
    return new Promise((resolve, reject) => {
      this.orderDepositInput.orderId = orderId;
      this.depositAmountResult = 0;
      this.isPaidStatus = isPaidStatus;

      const client = new ApiClientFactory().orderClient();
      client
        .getOrderDeposit(orderId)
        .then((result) => {
          if (result) {
            this.orderDeposits = result;
          }
        })
        .catch((err) => {
          this.$bvToast.toast('Tải danh sách thất bại', {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            appendToast: true
          });
        });

      this.$bvModal.show(MODAL_IDS.ORDER_DEPOSIT_AMOUNT_MODAL);

      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  loadOrderDeposits(orderId) {
    const client = new ApiClientFactory().orderClient();
    client.getOrderDeposit(orderId).then((result) => {
      if (result) {
        this.orderDeposits = result;
      }
    });
  }

  onSubmitDeposit(event) {
    event.preventDefault();
    if (this.orderDepositInput.amount === 0) {
      return;
    }
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }
    if (this.isPaidStatus) {
      if (this.isUpdating) {
        const index = this.orderDepositInput.id ?? 0;
        this.orderDeposits[index].amount = convertCurrencyToNumber(
          this.orderDepositInput.amount
        );
        console.log(this.orderDeposits[index].amount);
        this.orderDeposits[
          index
        ].paymentMethod = this.orderDepositInput.paymentMethod;
        this.orderDeposits[index].note = this.orderDepositInput.note;
        this.orderDeposits[index].date = new Date();

        this.isUpdating = false;
        this.resetInput();
        return;
      }
      const tempDepositRecord = {
        amount: convertCurrencyToNumber(this.orderDepositInput.amount),
        date: new Date(),
        paymentMethod: this.orderDepositInput.paymentMethod,
        note: this.orderDepositInput.note,
        orderId: this.orderDepositInput.orderId,
        id: ''
      } as OrderDepositModel;

      this.orderDeposits.push(tempDepositRecord);
      this.resetInput();
      return;
    }

    const client = new ApiClientFactory().orderClient();
    client
      .setOrderDeposit([this.orderDepositInput])
      .then((result) => {
        if (!isNaN(result) && result !== 0) {
          this.$bvToast.toast('Tạm ứng thành công', {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            appendToast: true
          });

          this.depositAmountResult = result;
          this.loadOrderDeposits(this.orderDepositInput.orderId);

          this.resetInput();
          this.isUpdating = false;
        } else {
          this.$bvToast.toast('Tạm ứng thất bại', {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            appendToast: true
          });
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Tạm ứng thất bại', {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          appendToast: true
        });
      });
    //this.$emit('update:depositAmount', this.depositAmount);
  }

  resetInput() {
    this.orderDepositInput.id = GUID_EMPTY;
    this.orderDepositInput.amount = 0;
    this.orderDepositInput.paymentMethod = 0;
    this.orderDepositInput.note = '';
    this.orderDepositInput.date = new Date();
  }

  displayPaymentMethod(data: OrderDepositModel) {
    const text =
      this.orderPaymentMethodOptionFilters.find(
        (s) => s.value == data.paymentMethod
      )?.text ?? '';
    return text ? this.$t(text) : '';
  }

  onPaidStatusCloseModal() {
    this.resetInput();
    if (this.isPaidStatus) {
      return this.resolvePromise(this.orderDeposits);
    }
    this.resetInput();
    return this.resolvePromise(this.depositAmountResult);
  }

  deleteDepositItem(id, index) {
    const regex = /^[a-z,0-9,-]{36,36}$/;
    if (this.isPaidStatus) {
      if (regex.test(id)) {
        this.$bvToast.toast(
          'Không thể chỉnh sửa tạm ứng, Vui lòng chỉnh sửa tại trang tạm ứng',
          {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            appendToast: true
          }
        );
        return;
      }
      this.orderDeposits.splice(index, 1);
      return;
    }

    const client = new ApiClientFactory().orderClient();
    client
      .deleteOrderDeposit(id)
      .then((result) => {
        if (result) {
          this.$bvToast.toast('Xóa tạm ứng thành công', {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            appendToast: true
          });

          //this.depositAmountResult = result;
          this.loadOrderDeposits(this.orderDepositInput.orderId);

          this.orderDepositInput.amount = 0;
          this.orderDepositInput.paymentMethod = 0;
          this.orderDepositInput.note = '';
          this.orderDepositInput.date = new Date();
        } else {
          this.$bvToast.toast('Xóa tạm ứng thất bại', {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            appendToast: true
          });
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Xóa tạm ứng thất bại', {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          appendToast: true
        });
      })
      .finally(() => {
        this.resetInput();
        this.depositAmountResult = 1;
      });
  }

  onRowClick(params) {
    // params.row - row object
    // params.pageIndex - index of this row on the current page.
    // params.selected - if selection is enabled this argument
    // indicates selected or not
    // params.event - click event
    if (
      this.orderDeposits.findIndex(
        (s) =>
          s.id == params.row.id &&
          s.amount == params.row.amount &&
          s.note == params.row.note &&
          s.paymentMethod == params.row.paymentMethod
      ) === -1
    ) {
      return;
    }

    const regex = /^[a-z,0-9,-]{36,36}$/;
    if (this.isPaidStatus && regex.test(params.row.id)) {
      this.$bvToast.toast(
        'Không thể chỉnh sửa tạm ứng, Vui lòng chỉnh sửa tại trang tạm ứng',
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          appendToast: true
        }
      );
      return;
    }
    this.isUpdating = true;

    this.orderDepositInput.amount = params.row.amount;
    this.orderDepositInput.paymentMethod = params.row.paymentMethod;
    this.orderDepositInput.note = params.row.note;
    this.orderDepositInput.date = params.row.date;
    this.orderDepositInput.id = this.isPaidStatus
      ? params.row.originalIndex
      : params.row.id;
  }
}
