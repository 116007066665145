































































































import {
  CreateOrderDetailInput,
  CreateOrderInput,
  OrderListViewModel,
  OrderPaymentMethod,
  OrderPaymentStatus,
  OrderStatus,
  ProductViewModel,
  UpdateOrderPaymentMethodInput,
  UpdateOrderPaymentStatusInput,
  UpdateOrderStatusInput
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  ProductAction,
  ProductState,
  PRODUCT_STATE_NAMESPACE
} from '@/store/product/product.module-types';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  formatCurrency,
  convertCurrencyToNumber,
  enumToDropdownOptions,
  StringFormat
} from '@/utility/utils';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BContainer,
  BRow,
  BCol,
  BDropdownDivider
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BContainer,
    BRow,
    BCol,
    BDropdownDivider
  },
  computed: { ...mapState(PRODUCT_STATE_NAMESPACE, [ProductState.products]) },
  methods: {
    ...mapActions(PRODUCT_STATE_NAMESPACE, [ProductAction.fetchProducts])
  }
})
export default class OrderStastiticNavBar extends Vue {
  @Prop({ type: Array, required: true })
  orders!: OrderListViewModel[];

  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus).map(
    (s) => {
      return {
        value: s.value,
        text: s.text
      };
    }
  );
  orderPaymentMethodOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentMethod
  ).map((s) => {
    return {
      value: s.value,
      text: s.text
    };
  });
  get orderPaymentStatusOptions(): {
    text: string;
    value: string;
    disable: boolean;
  }[] {
    const values = enumToDropdownOptions(OrderPaymentStatus);
    if (!AuthStorageService.hasAccountantPermissions()) {
      return values.map((s) => {
        return {
          text: s.text,
          value: s.value,
          disable: s.value === OrderPaymentStatus.Paid
        };
      });
    }

    return values.map((s) => {
      return {
        text: s.text,
        value: s.value,
        disable: false
      };
    });
  }

  // Methods will be component methods
  formatCurrency = formatCurrency;

  get getStatistics() {
    let totalAmount = 0;
    let remainAmount = 0;
    let paidAmount = 0;

    this.orders.forEach((value) => {
      if (value.paymentStatus != OrderPaymentStatus.Billing) {
        totalAmount += value.totalCustomerPay;
        remainAmount += value.remainTotalCustomerPay;
        paidAmount += value.depositAmount;
      }
    });

    return {
      totalAmount,
      remainAmount,
      paidAmount
    };
  }

  updateOrderStatus(status: number) {
    const client = new ApiClientFactory().orderClient();
    const body = {
      ids: this.orders.map((s) => s.id),
      status
    } as UpdateOrderStatusInput;

    client.updateStatus(body).then(() => {
      this.$emit('updateStatus');
      this.$bvToast.toast(
        StringFormat(
          this.$t('UpdateStatusSuccessfully').toString(),
          this.$t('Order').toString().toLowerCase()
        ),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  updateOrderPaymentStatus(status) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    if (
      status === OrderPaymentStatus.Paid &&
      !AuthStorageService.hasAccountantPermissions()
    ) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    const client = new ApiClientFactory().orderClient();
    const body = {
      ids: this.orders.map((s) => s.id),
      paymentStatus: status
    } as UpdateOrderPaymentStatusInput;

    client.updatePaymentStatus(body).then(() => {
      this.$emit('updateStatus');
      this.$bvToast.toast(
        this.$t('UpdatePaymentStatusSuccessfully').toString(),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  updateOrderPaymentMethod(value) {
    const updatePaymentMethodInput: UpdateOrderPaymentMethodInput = {
      orderIds: this.orders.map((s) => s.id),
      paymentMethod: value
    } as UpdateOrderPaymentMethodInput;

    const client = new ApiClientFactory().orderClient();
    client
      .updateOrderPaymentMethod(updatePaymentMethodInput)
      .then(() => {
        this.$emit('updateStatus');
        this.$bvToast.toast(
          StringFormat(
            this.$t('UpdatePaymentMethodSuccessfully').toString(),
            this.$t('Order').toString().toLowerCase()
          ),
          {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          }
        );
      })
      .catch((err) => {
        this.$bvToast.toast(err, {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'error'
        });
      });
  }
}

export enum OrderPaymentStatusNavbar {
  NotPaid = 1,
  //PaidDeposit = 2,
  Debt = 3,
  Paid = 4,
  NonPerformingLoan = 5,
  Billing = 6
}
