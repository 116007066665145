





















































































































































import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BContainer,
  BRow,
  BCol,
  BDropdownDivider,
  BSidebar,
  BImg,
  BModal,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BTooltip
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { format, formatDistanceToNow } from 'date-fns';
import viLocale from 'date-fns/locale/vi';
import { ActivityLogModel } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';

// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BContainer,
    BRow,
    BCol,
    BDropdownDivider,
    BSidebar,
    BImg,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BTooltip
  }
})
export default class ActivityLogModal extends Vue {
  // @Prop({ type: Array, required: true })
  items: ActivityLogModel[] = [];

  @Prop({ type: Boolean, required: false })
  reverse: boolean | null | undefined;

  @Prop({ type: Boolean, required: false })
  loading: boolean | null | undefined;

  @Prop({ type: String, required: false })
  dateFormat: string | null | undefined;

  @Prop({ type: String, required: false })
  variant: string | null | undefined;

  @Prop({ type: Object, required: false })
  humanFriendlyTime: boolean | null | undefined;

  $refs!: {
    modal: InstanceType<typeof BModal>;
  };

  get friendlyTime() {
    return this.humanFriendlyTime || true;
  }

  displayItems: any[] = [];

  openModal(entityName, entityId, showId): void {
    const client = new ApiClientFactory().activityLogClient();
    client
      .getActivityLogs(entityName, entityId)
      .then((result) => {
        if (result) {
          this.items = result;
          this.prepareItems(showId);

          this.$refs.modal.show();
        } else {
          this.$bvToast.toast('Tải nhật ký thất bại', {
            title: this.$t('ActivityLog').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((ex) => {
        this.$bvToast.toast('Tải nhật ký thất bại', {
          title: this.$t('ActivityLog').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  prepareItems(showId: boolean) {
    this.displayItems = this.items.map((v) => {
      if (v.type === 0 || v.type === 2) {
        return {
          title: `<strong>${v.userName}</strong> ${this.$t(
            v.typeDesc ?? ''
          )} ${this.$t('ActivityLog_' + v.entityName ?? '')} ${
            showId ? v.entityId : ''
          }`,
          timestamp: Date.parse(v.date.toLocaleString() ?? new Date())
        };
      } else {
        let logs = '';
        if (v.newValues) {
          const newValueKeys = Object.keys(v.newValues);
          for (let index = 0; index < newValueKeys.length; index++) {
            const element = newValueKeys[index];
            logs += `${this.$t('ActivityLog_' + v.entityName ?? '')} <strong> ${
              showId ? v.entityId : ''
            }</strong> thay đổi ${this.$t(
              'ActivityLog_Props_' + element
            )}: <strong>${this.$t(v.newValues[element])}</strong> <break/>`;
          }
        }

        return {
          title: `<strong>${v.userName}</strong> ${this.$t(
            v.typeDesc ?? ''
          )} ${this.$t('ActivityLog_' + v.entityName ?? '')}`,
          timestamp: Date.parse(v.date.toLocaleString() ?? new Date()),
          content: logs
        };
      }
    });
  }

  bootstrapVariant() {
    return this.variant || 'primary';
  }
  orderedItems() {
    let orderItems = this.displayItems;
    if (this.loading) {
      orderItems = [
        ...orderItems,
        { spinner: true, timestamp: 'time', title: 'loading' }
      ];
    }

    if (this.reverse) {
      orderItems.reverse();
    }

    return orderItems;
  }
  itemsCount() {
    if (this.loading) {
      return this.items.length + 1;
    }

    return this.items.length;
  }
  formatAgo(timestamp) {
    return formatDistanceToNow(timestamp, {
      addSuffix: true,
      locale: viLocale
    });
  }
  formatFull(timestamp) {
    const dateFormat = this.dateFormat || 'dd-MM-yyyy HH:mm:ss';
    return format(timestamp, dateFormat);
  }
  timestampElementId(item) {
    return item.timestamp + item.title + '-timestamp';
  }
}
