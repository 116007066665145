import {
  CustomerLevel,
  CustomerViewModel,
  MasterDataViewModel,
  OrderDetailStatus,
  OrderPaymentMethod,
  OrderStatistics,
  SelectModelOfString,
  UpdateOrderPaymentMethodInput
} from './../../../api/api';
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BContainer,
  BAvatar,
  BPagination,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink,
  BFormDatepicker,
  BTooltip,
  BInputGroupAppend,
  BInputGroupText,
  BDropdownForm,
  BFormTextarea,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  OrderListViewModel,
  OrderPaymentStatus,
  UpdateOrderPaymentStatusInput,
  UpdateOrderStatusInput,
  OrderStatus,
  DownloadOrderReportInput,
  EmployeeListViewModel,
  OrderDetailViewModel
} from '@/api/api';
import {
  orderListDispatcher,
  OrderListInput,
  orderListNamespace
} from './store/order-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  getItemsPerPage,
  getValueOrUndefined
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  enumToDropdownOptions,
  StringFormat,
  formatCurrency,
  formatDate,
  convertCurrencyToNumber
} from '@/utility/utils';
import router from '@/router';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeState,
  EmployeeAction
} from '@/store/employee/employee.module-types';
import OrderItemDetailsModal from './modals/OrderItemDetailsModal.vue';
import OrderDetailModal from '../orderDetail/OrderDetailModal.vue';
import { isThisSecond } from 'date-fns';
import {
  CustomerAction,
  CustomerGetter,
  CustomerState,
  CUSTOMER_STATE_NAMESPACE
} from '@/store/customer/customer.module-types';
import { PriceNamespace } from '@/store/price/price.module-types';
import { OrderAction } from '@/store/order/order.module-types';
import { dispatchOrderAction } from '@/store/order/order.dispatch';
import { MODAL_IDS, PAYMENT_NOTE_TYPE } from '@/utility/constants';
import PaymentNoteModal from './modals/PaymentNoteModal.vue';
import ProducingStatusSumary from '../../producing/ProducingStatusSumary.vue';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';
import DepositInputModal from './modals/DepositInputModal.vue';
import ActivityLogSummaryModal from './modals/ActivityLogSummaryModal.vue';
@Component({
  components: {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    vSelect,
    BFormDatepicker,
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BDropdownForm,
    BFormTextarea,
    OrderItemDetailsModal,
    OrderDetailModal,
    BOverlay,
    PaymentNoteModal,
    ProducingStatusSumary,
    DepositInputModal,
    ActivityLogSummaryModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: '',
        isExportBilling: undefined
      },
      dispatcher: orderListDispatcher,
      debouncedWatchers: [
        (listInput) => listInput.searchText,
        (listInput) => listInput.filterEmployee
      ]
    })
  ],
  computed: {
    ...mapState(CUSTOMER_STATE_NAMESPACE, [CustomerState.customers]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [
      EmployeeAction.fetchSaleEmployees
    ]),
    ...mapActions(CUSTOMER_STATE_NAMESPACE, [CustomerAction.fetchCustomers]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class OrderList
  extends Vue
  implements ListVue<OrderListViewModel, OrderListInput> {
  @orderListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    OrderListViewModel,
    OrderListInput
  >[ListGetter.state];
  @Prop({ type: Boolean, required: false })
  isBillingOrderTab: boolean | undefined;

  customers!: CustomerViewModel[];
  fetchCustomers!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;
  saleEmployees!: SelectModelOfString[];
  fetchSaleEmployees!: () => Promise<void>;

  $refs!: {
    paymentMethodRef: any;
    paymentMethodFormRef: any;
    bankingNoteRef: any;
    depositInputModalRef: any;
    activityLogSummaryModal: any;
  };

  orderListDispatcher = orderListDispatcher;
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  ORDER_STATUS_ENUM = OrderStatus;
  ORDER_PAYMENT_STATUS_ENUM = OrderPaymentStatus;
  formatCurrency = formatCurrency;
  formatDate = formatDate;
  MODAL_IDS = MODAL_IDS;
  ORDER_PAYMENT_STATUS_PREFIX = 'OrderPaymentStatus';
  ORDER_STATUS_PREFIX = 'OrderStatus';

  hasFullPermission = AuthStorageService.isFullPermission();

  isLoading = false;
  orderSelectedRows: OrderListViewModel[] = [];
  orderStatusOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderStatusFilter
  ).map((s) => {
    return {
      value: `${this.ORDER_STATUS_PREFIX}-${s.value}`,
      text: s.text
    };
  });
  orderPaymentStatusOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentStatus
  ).map((s) => {
    return {
      value: `${this.ORDER_PAYMENT_STATUS_PREFIX}-${s.value}`,
      text: s.text
    };
  });
  filterOptionButtons: {
    value: DropdownOption;
    isSelecting: boolean;
    count: number;
  }[] = [];

  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  get orderPaymentStatusOptions(): {
    text: string;
    value: string;
    disable: boolean;
  }[] {
    const values = enumToDropdownOptions(OrderPaymentStatus);
    if (!AuthStorageService.hasAccountantPermissions()) {
      return values.map((s) => {
        return {
          text: s.text,
          value: s.value,
          disable: s.value === OrderPaymentStatus.Paid
        };
      });
    }

    return values.map((s) => {
      return {
        text: s.text,
        value: s.value,
        disable: false
      };
    });
  }

  get pageSizeOptions() {
    return DEFAULT_PAGE_SIZE_OPTIONS;
  }

  get employeeOptions(): DropdownOption[] {
    let result: DropdownOption[] = [];
    result = this.saleEmployees?.map(
      (x) =>
        ({
          value: x.id,
          text: x.name
        } as DropdownOption)
    );

    result.unshift({
      text: 'Tất cả',
      value: ''
    });
    return result;
  }

  totalCustomerPay = 0;
  remainTotalCustomerPay = 0;
  isDownloadingReport = false;
  bankingNoteInput = '';
  noteData: {
    orderId: number;
    type: string;
    amount: number | null | undefined;
    note: string | null | undefined;
  } = {
    orderId: 0,
    type: PAYMENT_NOTE_TYPE.COD,
    amount: 0,
    note: ''
  };
  orderActionOpened = false;

  header = [
    // {
    //   label: this.$t('OrderCode'),
    //   field: 'id',
    //   thClass: 'order-header-style'
    // },
    {
      label: this.$t('Customer'),
      field: 'customerName',
      width: '20%',
      thClass: 'order-header-style ml-1 font-weight-500'
    },
    // {
    //   label: this.$t('DateCreated'),
    //   field: 'dateCreated',
    //   thClass: 'order-header-style'
    // },
    // {
    //   label: this.$t('DeliveryDate'),
    //   field: 'deliveryDate',
    //   thClass: 'order-header-style'
    // },
    {
      label: this.$t('TotalPrice'),
      field: 'totalCustomerPay',
      thClass: 'order-header-style',
      width: '12%'
    },
    // {
    //   label: this.$t('PaidAmount'),
    //   field: 'depositAmount',
    //   thClass: 'order-header-style',
    //   width: '8%'
    // },
    // {
    //   label: this.$t('PaymentMethod'),
    //   field: 'paymentMethod',
    //   thClass: 'order-header-style',
    //   width: '5%'
    // },
    {
      label: this.$t('RemainAmount'),
      field: 'remainTotalCustomerPay',
      thClass: 'order-header-style',
      tdClass: 'no-wrap',
      width: '10%'
    },
    // {
    //   label: this.$t('COD'),
    //   field: 'codAmount',
    //   thClass: 'order-header-style',
    //   width: '8%'
    // },
    // {
    //   label: this.$t('Service'),
    //   field: '_services'
    // },
    {
      label: this.$t('BankingNote'),
      field: 'bankingNote',
      thClass: 'order-header-style',
      width: '15%'
    },
    {
      label: this.$t('Tình trạng sản xuất'),
      field: 'producingStatus',
      thClass: 'order-header-style',
      width: '15%'
    },
    {
      label: this.$t('Status'),
      field: 'status',
      width: '10%',
      thClass: 'order-header-style'
    },
    {
      label: this.$t('PaymentStatus'),
      field: 'paymentStatus',
      width: '10%',
      thClass: 'order-header-style'
    },
    // {
    //   label: 'NVKD',
    //   field: 'employeeName',
    //   thClass: 'order-header-style',
    //   width: '2%'
    // },
    {
      label: '',
      field: '_action',
      sortable: false,
      thClass: 'order-header-style w-fit-content',
      tdClass: 'vgt-right-align w-fit-content'
    }
  ];

  PAYMENT_NOTE_TYPE = PAYMENT_NOTE_TYPE;

  getTotalCustomerPay(order: OrderListViewModel) {
    const vatAmount =
      (convertCurrencyToNumber(order.vatAmount) / 100) * order.totalCustomerPay;
    const total =
      convertCurrencyToNumber(order.shippingFee) +
      vatAmount +
      order.totalCustomerPay -
      convertCurrencyToNumber(order.discountAmount);
    return total < 0 ? 0 : total;
  }

  trimText(text) {
    if (text) {
      return text.trim().substring(0, 50) + '…';
    }
    return '';
  }

  displayOrderStatus(value) {
    return this.orderStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.orderStatusOptions.find((x) => x.value === value)?.text as string
        )
      : '';
  }

  displayOrderPaymentStatus(value) {
    return this.orderPaymentStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.orderPaymentStatusOptions.find((x) => x.value === value)
            ?.text as string
        )
      : '';
  }

  displayCustomerLevel(value) {
    const customerLevels = enumToDropdownOptions(CustomerLevel);
    return customerLevels.find((x) => x.value === value)
      ? this.$t(customerLevels.find((x) => x.value === value)?.text as string)
      : '';
  }

  displayEmployeeName(value: string) {
    const names = value.split(' ');
    return names.length === 1 ? value : names.map((n) => n[0]).join('');
  }

  mounted() {
    this.fetchSaleEmployees().then(() => {
      if (!this.customers || this.customers?.length === 0) {
        this.fetchCustomers();
      }

      if (!this.masterData) {
        this.fetchMasterData();
      }

      this.filterOptionButtons = this.initButtonSelected();
      this.listState.input.isExportBilling = this.isBillingOrderTab;
      if (!this.listState.input.dateTo) {
        this.listState.input.dateTo = new Date();
      }
      if (!this.listState.input.dateFrom) {
        this.listState.input.dateFrom = new Date();
        this.listState.input.dateFrom.setDate(
          this.listState.input.dateFrom.getDate() - 7
        );
      }

      orderListDispatcher.load();
      this.getOrderStatusStatistics();
    });
  }

  reload() {
    orderListDispatcher.load();
    this.getOrderStatusStatistics();
  }

  view(id) {
    this.openOrderDetailModal(id);
  }

  edit(id: number) {
    const order = this.listState.items.find((s) => s.id === id);
    if (
      !order ||
      !this.isOrderAbleToUpdate(order.status, order.orderDetails ?? [])
    ) {
      return;
    }

    this.$emit('OnEditOrder', id);
  }

  remove(id: number) {
    if (this.hasFullPermission) {
      const client = new ApiClientFactory().orderClient();
      client.delete(id).then(() => {
        orderListDispatcher.load();
        // show toast
        this.$bvToast.toast('Xóa đơn hàng thành công', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
    } else {
      this.$bvToast.toast('Bạn không có quyền xóa đơn hàng', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }
  }

  rowStyleClassFn(row: OrderListViewModel) {
    return OrderPaymentStatus[row.paymentStatus];
  }

  updateStatus(id: number, status: number) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }
    const order = this.listState.items.find((s) => s.id === id);
    if (
      !order ||
      !this.isOrderAbleToUpdate(order.status, order.orderDetails ?? [])
    ) {
      return;
    }

    const client = new ApiClientFactory().orderClient();

    const body = {
      ids: [id],
      status
    } as UpdateOrderStatusInput;

    client.updateStatus(body).then(() => {
      orderListDispatcher.load();
      this.getOrderStatusStatistics();
      this.$bvToast.toast(
        StringFormat(
          this.$t('UpdateStatusSuccessfully').toString(),
          this.$t('Order').toString().toLowerCase()
        ),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  async updatePaymentStatus(id: number, paymentStatus: number) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    if (
      paymentStatus === OrderPaymentStatus.Paid &&
      !AuthStorageService.hasAccountantPermissions()
    ) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    const order = this.listState.items.find((s) => s.id === id);
    if (
      !order ||
      !this.isOrderAbleToUpdate(order.status, order.orderDetails ?? [])
    ) {
      return;
    }

    const body = {
      ids: [id],
      paymentStatus: paymentStatus,
      orderDepositModels: undefined
    } as UpdateOrderPaymentStatusInput;

    let isDepositSuccess = true;
    const client = new ApiClientFactory().orderClient();
    if (
      paymentStatus == OrderPaymentStatus.PaidDeposit ||
      paymentStatus == OrderPaymentStatus.Paid
    ) {
      isDepositSuccess = false;
      const isPaidStatus = paymentStatus == OrderPaymentStatus.Paid;
      const depositResult = await this.$refs.depositInputModalRef.show(
        id,
        isPaidStatus
      );

      if (
        paymentStatus == OrderPaymentStatus.PaidDeposit &&
        depositResult &&
        depositResult != 0
      ) {
        isDepositSuccess = true;
      }

      if (depositResult?.length > 0 && isPaidStatus) {
        const totalPaid = depositResult.reduce((partialSum, a) => {
          return partialSum + a.amount;
        }, 0);

        const total =
          order.totalCustomerPay +
          order.totalCustomerPay * (order.vatAmount / 100);
        if (totalPaid === total) {
          const client = new ApiClientFactory().orderClient();
          try {
            const tempDeposits = depositResult.filter((s) => !s.id);
            const result = await client.setOrderDeposit(tempDeposits);
            if (!isNaN(result) && result !== 0) {
              isDepositSuccess = true;
              this.$bvToast.toast('Thanh toán thành công', {
                title: this.$t('Order').toString(),
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                appendToast: true
              });
            } else {
              this.$bvToast.toast('Thanh toán thất bại', {
                title: this.$t('Order').toString(),
                toaster: 'b-toaster-bottom-right',
                variant: 'danger',
                appendToast: true
              });
            }
          } catch (error) {
            this.$bvToast.toast('Thanh toán thất bại', {
              title: this.$t('Order').toString(),
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              appendToast: true
            });
          }

          orderListDispatcher.load();
          //return;
        } else {
          this.$bvToast.toast(
            'Tổng tiền thanh toán phải bằng số tiền phải trả',
            {
              title: this.$t('Order').toString(),
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              appendToast: true
            }
          );
        }
      }
    }

    if (isDepositSuccess) {
      client.updatePaymentStatus(body).then(() => {
        orderListDispatcher.load();
        this.getOrderStatusStatistics();
        this.$bvToast.toast(
          this.$t('UpdatePaymentStatusSuccessfully').toString(),
          {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          }
        );
      });
    }
  }

  selectionChanged(params: { selectedRows: OrderListViewModel[] }) {
    this.orderSelectedRows = params.selectedRows;
    this.$emit('onSelectOrder', params.selectedRows);
  }

  downloadReport() {
    let from, to;
    if (this.listState.input.dateFrom) {
      from = new Date(
        this.listState.input.dateFrom.getFullYear(),
        this.listState.input.dateFrom.getMonth(),
        this.listState.input.dateFrom.getDate(),
        0,
        0,
        0
      );
    }

    if (this.listState.input.dateTo) {
      // input.dateTo.setDate(input.dateTo.getDate() + 1);
      to = new Date(
        this.listState.input.dateTo.getFullYear(),
        this.listState.input.dateTo.getMonth(),
        this.listState.input.dateTo.getDate(),
        0,
        0,
        0
      );
      to.setDate(to.getDate() + 1);
    }

    const client = new ApiClientFactory().orderClient();
    this.isDownloadingReport = true;
    client
      .report(
        new DownloadOrderReportInput({
          dateFrom: from ? from.getTime() : null,
          dateTo: to ? to.getTime() : null,
          filterStatus: getValueOrUndefined(
            this.listState.input.filterStatus?.map((_) => _.value)
          ),
          filterPaymentStatus: getValueOrUndefined(
            this.listState.input.filterPaymentStatus?.map((_) => _.value)
          ),
          orderIds: this.orderSelectedRows.map((s) => s.id)
        })
      )
      .then((response) => {
        this.isDownloadingReport = false;
        const url = window.URL.createObjectURL(
          response?.data as Blob | MediaSource
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.click();
      });
  }

  onFilterButton(selectedItem: DropdownOption): void {
    this.filterOptionButtons = this.filterOptionButtons.map((item) => {
      return { ...item, isSelecting: false };
    });

    const selectedValues = (selectedItem.value as string).split('-');
    const selectedValueIndex = this.filterOptionButtons.findIndex(
      (s) => s.value.value === selectedItem.value
    );

    const exsitingStatusIndex =
      selectedValues[0] === 'OrderStatus'
        ? this.listState.input.filterStatus?.findIndex(
            (s) => s.value === selectedValues[1]
          ) ?? -1
        : this.listState.input.filterPaymentStatus?.findIndex(
            (s) => s.value === selectedValues[1]
          ) ?? -1;

    this.listState.input.filterPaymentStatus = [];
    this.listState.input.filterStatus = [];

    if (exsitingStatusIndex === -1) {
      if (selectedValues[0] === 'OrderStatus') {
        this.listState.input.filterStatus?.push({
          value: selectedValues[1],
          text: selectedItem.text
        });
      } else {
        this.listState.input.filterPaymentStatus?.push({
          value: selectedValues[1],
          text: selectedItem.text
        });
      }

      this.filterOptionButtons[selectedValueIndex].isSelecting = true;
    }

    orderListDispatcher.load();
  }

  initButtonSelected() {
    return this.orderStatusOptionFilters
      .concat(this.orderPaymentStatusOptionFilters)
      .map((val) => {
        return {
          value: val,
          isSelecting: false,
          count: 0
        };
      });
  }

  onEmployeeFilterChange(value) {
    this.listState.input.filterEmployee = value?.value ?? '';
    this.getOrderStatusStatistics();
  }

  async getOrderDetail(id: number, customerName: string) {
    // const client = new ApiClientFactory().orderClient();
    // const orderDetail = await client.getOrderDetailByOrderId(id);
    // this.orderDetails = orderDetail;
    this.isLoading = true;
    dispatchOrderAction(OrderAction.loadOrder, id);
    this.isLoading = false;
    this.$bvModal.show(MODAL_IDS.ORDER_ITEM_DETAIL_MODAL);
    // this.customerName = customerName;
    // this.selectedOrderId = id;
  }

  async openOrderDetailModal(id) {
    this.isLoading = true;
    dispatchOrderAction(OrderAction.loadOrder, id);
    this.isLoading = false;
    this.$bvModal.show(MODAL_IDS.ORDER_DETAIL_MODAL);
  }

  onCODNoteClick(orderId) {
    const selectedOrder = this.listState.items.find((s) => s.id === orderId);
    if (selectedOrder) {
      this.noteData = {
        orderId: orderId,
        type: PAYMENT_NOTE_TYPE.COD,
        amount: selectedOrder.codAmount ?? 0,
        note: selectedOrder.codNote ?? ''
      };

      this.$bvModal.show(MODAL_IDS.PAYMENT_NOTE_MODAL);
    }
  }

  onBankTransferNoteClick(value, orderId) {
    if (orderId) {
      this.noteData = {
        orderId: orderId,
        type: PAYMENT_NOTE_TYPE.BankTransfer,
        amount: null,
        note: value ?? ''
      };

      this.$bvModal.show(MODAL_IDS.PAYMENT_NOTE_MODAL);
    }
  }

  getOrderStatusStatistics() {
    const client = new ApiClientFactory().orderClient();
    client
      .getOrderStatusStatistics(
        this.listState.input.filterEmployee,
        this.listState.input.dateFrom?.getTime(),
        this.listState.input.dateTo?.getTime()
      )
      .then((result: OrderStatistics) => {
        this.filterOptionButtons = this.filterOptionButtons.map((item) => {
          const itemValue = (item.value.value as string).split('-');
          let statisticValue = 0;
          if (
            itemValue.length > 0 &&
            itemValue.includes(this.ORDER_STATUS_PREFIX)
          ) {
            statisticValue = result.orderStatus
              ? result.orderStatus[itemValue[1]]
              : 0;
          } else if (
            itemValue.length > 0 &&
            itemValue.includes(this.ORDER_PAYMENT_STATUS_PREFIX)
          ) {
            statisticValue = result.orderPaymentStatus
              ? result.orderPaymentStatus[itemValue[1]]
              : 0;
          }

          return { ...item, count: statisticValue };
        });
      });
  }

  async reloadList(selectedIds: number[]) {
    await orderListDispatcher.load();
    this.getOrderStatusStatistics();
    selectedIds.forEach((element) => {
      const index = this.listState.items.findIndex((s) => s.id === element);
      if (index !== -1 && !this.listState.items[index]['vgtSelected'])
        this.$set(this.listState.items[index], 'vgtSelected', true);
    });

    this.$nextTick(() => {
      selectedIds.forEach((obj) => {
        // loop over each invitee
        const row = document.getElementById('row-id-' + obj); // find row that matches our invited invitee
        if (row) {
          // simple guard to ensure it's there (it should be but whatever)
          row.parentElement?.parentElement?.classList.add('selected');
        }
      });
    });
  }

  updateOrderActionState(value, id) {
    const element = document.getElementsByClassName(
      'text-body align-right align-self-center ' + id
    );
    if (element) {
      if (value) {
        element[0]?.classList?.add('rotate');
      } else {
        element[0]?.classList?.remove('rotate');
      }
    }
    this.orderActionOpened = value;
  }

  isOrderAbleToUpdate(
    orderStatus: OrderStatus,
    orderDetails: OrderDetailViewModel[]
  ) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền sửa đơn hàng', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return false;
    }

    const inValidToUpdate =
      orderStatus === OrderStatus.Delivered &&
      orderDetails.findIndex(
        (s) => s.status !== OrderDetailStatus.Delivered
      ) === -1;
    if (inValidToUpdate && !this.hasFullPermission) {
      this.$bvToast.toast('Không thể chỉnh sửa đơn hàng đã hoàn thành', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }

    return !inValidToUpdate || this.hasFullPermission;
  }

  onPrintBill(orderId) {
    const newWindow = window.open(
      `/print-order/${orderId}?type=${PrintType.OrderDetail.toString()}`,
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;
  }

  activityLogClick() {
    this.$refs.activityLogSummaryModal.openModal();
  }
}

enum OrderStatusFilter {
  Ordered = 1,
  OrderReady = 4,
  Delivered = 5,
  Canceled = 6,
  InStock = 14,
  //Billing = 15,
  ExpressDelivery = 12,

  Done = 90,
  NotDone = 91
}
