



































































































































































































import {
  CatalogueOrderMaterial,
  CatalogueProductItemsPrice,
  CatalogueSizeOption,
  CreateOrderDetailInput,
  CreateOrderInput,
  PolymeCoverType,
  ProductViewModel
} from '@/api/api';
import { formatCurrency, convertCurrencyToNumber } from '@/utility/utils';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BSpinner,
  BOverlay
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import vSelect from 'vue-select';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { Prop, Watch } from 'vue-property-decorator';
import ApiClientFactory from '@/api/apiClientFactory';

// Define the component in class-style
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    CustomLabel,
    BFormSelectOption,
    BFormRadio,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BOverlay
  }
})
export default class OrderDetailCatalogueForm extends Vue {
  @Prop({ type: Object, required: true })
  orderDetailInput!: CreateOrderDetailInput;
  @Prop({ type: Object, required: true })
  catalogueOrderDetail!: {
    catalogueItemId: string;
    catalogueItemName: string;
    size: string;
    width: number;
    height: number;
    processingType: string;
    polymeCoverType: string;
    polymeCoverTypeName: string;
    totalPage: number;
    totalBook: number;
    pricePerUnit: number;
    totalPrice: number;
  };
  @Prop({ type: Boolean, required: true })
  isLoading!: boolean;

  paperOptions: {
    id: string;
    name: string;
  }[] = [];

  catalogueSizes: CatalogueSizeOption[] = [];
  polymeCoverTypes: PolymeCoverType[] = [];
  pageOptions = [0];
  bookOptions = [0];

  catalogueMaterials: CatalogueOrderMaterial[] = [];
  catalogueProductItemPrices: CatalogueProductItemsPrice[] = [];

  async fetchCatalogueMaterial(serviceId) {
    if (serviceId && serviceId > 0) {
      this.isLoading = true;
      const clientApi = new ApiClientFactory();
      const result = await clientApi
        .orderClient()
        .getCatalogueMaterials(serviceId);

      if (result && result.length > 0) {
        this.orderDetailInput.serviceId = serviceId;
        this.catalogueMaterials = result;
        this.pageOptions = result[0].pageOptions ?? [];
        this.bookOptions = result[0].bookOptions ?? [];
        this.catalogueSizes = result[0].sizeOptions ?? [];

        this.paperOptions = result.map((item) => {
          return {
            id: item.catalogueProductItemId,
            name: item.catalogueProductItemName ?? ''
          };
        });

        this.paperOptions.sort((a, b) => a.name.localeCompare(b.name));
        if (this.catalogueOrderDetail.width !== 0) {
          this.catalogueOrderDetail.size =
            this.catalogueSizes.find(
              (s) => s.width === this.catalogueOrderDetail.width
            )?.name ?? '';
        }
      }
    }

    this.isLoading = false;
  }

  async fetchCataloguePrice() {
    const clientApi = new ApiClientFactory();
    const result = await clientApi
      .priceClient()
      .getCatalogueProductItemsPrices(
        this.catalogueOrderDetail.catalogueItemId
      );
    this.catalogueProductItemPrices = result ?? [];
  }

  async setPolymeCoverTypes(resetCurrentValue) {
    if (this.catalogueOrderDetail.catalogueItemId) {
      this.isLoading = true;
      if (resetCurrentValue) {
        this.catalogueOrderDetail.polymeCoverType = '';
      }
      const catalogueItem = this.catalogueMaterials.find(
        (s) =>
          s.catalogueProductItemId === this.catalogueOrderDetail.catalogueItemId
      );
      this.polymeCoverTypes = catalogueItem?.polymeCoverTypes ?? [];
      this.catalogueOrderDetail.catalogueItemName =
        catalogueItem?.catalogueProductItemName ?? '';

      await this.fetchCataloguePrice();
    }

    this.isLoading = false;
  }

  async onPriceChange(isManualInput) {
    if (
      !this.catalogueProductItemPrices ||
      (this.catalogueProductItemPrices?.length === 0 && !isManualInput)
    ) {
      await this.fetchCataloguePrice();
    }
    if (this.catalogueProductItemPrices?.length > 0 && !isManualInput) {
      const pricePerUnit =
        this.catalogueProductItemPrices.find(
          (s) =>
            s.totalBook === this.catalogueOrderDetail.totalBook &&
            s.totalPage === this.catalogueOrderDetail.totalPage
        )?.price ?? 0;

      this.catalogueOrderDetail.pricePerUnit = pricePerUnit;
    }
    this.catalogueOrderDetail.totalPrice =
      (this.catalogueOrderDetail.totalBook ?? 0) *
      (convertCurrencyToNumber(this.catalogueOrderDetail.pricePerUnit) ?? 0);
  }

  onSizeChange() {
    const size = this.catalogueSizes.find(
      (s) => s.name == this.catalogueOrderDetail.size
    );

    this.catalogueOrderDetail.width = size?.width ?? 0;
    this.catalogueOrderDetail.height = size?.height ?? 0;
    this.onPriceChange(false);
  }

  setPolymeCoverType() {
    this.catalogueOrderDetail.polymeCoverTypeName =
      this.polymeCoverTypes.find(
        (s) => s.id === this.catalogueOrderDetail.polymeCoverType
      )?.name ?? '';
  }
}
